import React from "react"

import { Outer, Inner } from "./DesktopSortStyles"
import {
  StyledSelectWrapper,
  StyledDropdownSelect,
  StyledSelectOption,
  StyledInputIconWrapper,
} from "../../../Styled/Form"
import { Icon } from "../../../Icon/Icon"
import { withDesktopSort } from "./withDesktopSort"
import { uuidPattern } from "../../../../helpers/utils"

export const DesktopSort = withDesktopSort(
  ({ options, sortBy, handleSortChange }): JSX.Element => (
    <Outer>
      <Inner>
        <StyledSelectWrapper>
          <StyledDropdownSelect
            value={sortBy?.id}
            onChange={handleSortChange}
            layout={"sortBy"}
          >
            {options?.map(option => {
              if (!uuidPattern.test(option.id)){
                return (
                  <StyledSelectOption key={option.id} value={option.id}>
                    {option.name}
                  </StyledSelectOption>
                )
              }
            })}
          </StyledDropdownSelect>
          <StyledInputIconWrapper>
            <Icon name={"chevron"} width={"12"} height={"12"} />
          </StyledInputIconWrapper>
        </StyledSelectWrapper>
      </Inner>
    </Outer>
  ),
)
