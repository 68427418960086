import React from "react"

import { Wrapper, Selected } from "./MobileSortStyles"
import { withMobileSort } from "./withMobileSort"
import { Accordion } from "../../../Accordion/Accordion"
import { CheckboxButton } from "../../../Checkbox/Checkbox"
import { uuidPattern } from "../../../../helpers/utils"

export const MobileSort = withMobileSort(
  ({ options, sortBy, handleSortChange }): JSX.Element => (
    <Wrapper>
      <Accordion
        title={"Sort"}
        layout={"filter"}
        subtitle={() => <Selected>{sortBy?.name}</Selected>}
      >
        {options?.map(option => {
          if (!uuidPattern.test(option.id)){
          return (
            <CheckboxButton
              key={option.id}
              label={option.name}
              checked={sortBy?.id === option.id}
              onChange={() => handleSortChange(option.id)} />
              )
            }
          })}
      </Accordion>
    </Wrapper>
  ),
)
