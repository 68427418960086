import React from "react"

import { Icon } from "../Icon/Icon"
import { Outer, Overlay, Inner, Close, Content } from "./PopupStyles"
import { withPopup } from "./withPopup"

export const Popup = withPopup(({ active, children, handleClick, width, mobileAttachTop, outerClose, attachTop, transparent }) => (
  <Outer active={active} mobileAttachTop={mobileAttachTop} attachTop={attachTop}>
    <Overlay onClick={handleClick} />
    {outerClose ? (
      <Close outerClose={outerClose ? "true" : "false"} onClick={handleClick} title={`close`}>
        <Icon name={`cross`} width={19} height={19} />
      </Close>
    ) : null}
    <Inner active={active} width={width}>
      {!outerClose ? (
        <Close outerClose={outerClose ? "true" : "false"} onClick={handleClick} title={`close`}>
          <Icon name={`cross`} />
        </Close>
      ) : null}
      <Content transparent={transparent ? "true" : "false"}>{children}</Content>
    </Inner>
  </Outer>
))
