import React from "react"
import { getAdditionalText } from "../../../utils/getAdditionalText"

export const withSearchPagination = Component => ({
  name = "SearchPagination",
  currentPage,
  totalPages,
  currentPageSize,
  handleSelectedSizeChange,
  sizeOptions,
  handlePageChange,
}) => {
  const {
    template: {
      collection: { additionalPageSizeText },
    },
  } = getAdditionalText()
  const active = totalPages > 1
  const handlePageChangeHandler = (page) => {
    handlePageChange(page - 1)
    window.scrollTo({top: 0, left: 0});
  }

  Component.displayName = name
  return (
    <Component
      active={active}
      currentPage={currentPage + 1}
      handlePageChangeHandler={handlePageChangeHandler}
      totalPages={totalPages}
      currentPageSize={currentPageSize}
      handleSelectedSizeChange={handleSelectedSizeChange}
      sizeOptions={sizeOptions}
      additionalPageSizeText={additionalPageSizeText}
    />
  )
}
