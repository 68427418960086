import React from "react"

import { withSections } from "./withSections"
import { Section } from "./Section"

export const Sections = React.memo(withSections(
  ({ content, trackSectionClick, ...props }): JSX.Element => (
    //the div here is used to fix an emotion build error on some pages. please don't remove
    <div>
      {content
        ?.filter(section => !!section)
        ?.map((section, idx) => (
          <Section key={section._key + 'section' + idx} settings={section?.settings} onClick={() => trackSectionClick(section._key)}>
            <section.component {...props} {...section} />
          </Section>
        ))}
    </div>
  ),
))
