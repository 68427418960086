import React, { useEffect, useState } from "react"
import { SeoBottomCopyWrapper, SeoBottomCopyText, SeoBottomCopyButton } from "./CollectionStyles"
import { useCore } from "../../hooks/useCore"

type Props = {
  seoCopy?: any
  readMoreSeoTextButton?: string
  readMoreSeoTextButtonActive?: string
}

const SeoBottomCopy: React.FC<Props> = ({ seoCopy, readMoreSeoTextButton, readMoreSeoTextButtonActive }) => {
  const {
    helpers: { isDomReady },
  } = useCore()
  const [showMoreButton, setShowMoreButton] = useState<boolean>(false) // used to show the button [read more
  const [maxHeight, setMaxHeight] = useState<number>(0) // used to set the max height of the text [read more
  const [showMore, toggleShowMore] = useState<boolean>(false)

  useEffect(() => {
    if (isDomReady) {
      setTimeout(() => {
        const element = document.querySelector(".bottomCopy")
        if (element) {
          const show = element.scrollHeight > element.clientHeight
          setMaxHeight(element.scrollHeight)
          setShowMoreButton(!!show)
        }
      }, 1000)
    }
  }, [isDomReady, seoCopy])

  return (
    <SeoBottomCopyWrapper>
      <SeoBottomCopyText className={`bottomCopy`} active={showMore} maxHeight={maxHeight}>
        {seoCopy}
      </SeoBottomCopyText>
      {showMoreButton && (
        <SeoBottomCopyButton buttonMargin={0} size={`tertiary`} colour={`underlinedLinkNavy`} onClick={() => toggleShowMore(!showMore)}>
          <span>{showMore ? readMoreSeoTextButtonActive : readMoreSeoTextButton}</span>
        </SeoBottomCopyButton>
      )}
    </SeoBottomCopyWrapper>
  )
}

export default SeoBottomCopy
