import { useFilters } from "@usereactify/search"
import React, { useMemo } from "react"


export const withAllFilters = Component => ({
  name = "AllFilters",
  filter,
  additionalAllOptionSelected,
  additionalOptionSelected,
}) => {
  const { selected } = useFilters()

  const currentFilter = useMemo(() => selected?.find(({handle}) => handle === filter.handle), [selected, filter.handle])
  const filterCount = useMemo(() => currentFilter?.value?.length || 0, [currentFilter?.value?.length])

  Component.displayName = name
  return (
    <Component
      filter={filter}
      additionalAllOptionSelected={additionalAllOptionSelected}
      additionalOptionSelected={additionalOptionSelected}
      filterCount={filterCount}
    />
  )
}