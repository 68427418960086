import React, { memo } from "react"
import { graphql } from "gatsby"

import { Collection as Template } from "../components/Collection/Collection"

import { useHotjar } from '../hooks/useHotjar'

export const query = graphql`
  query($handle: String!) {
    collection: sanityCollection(shopify: { shopifyHandle: { eq: $handle } }) {
      ...GatsbySanityCollectionFragment
    }
    template: sanityTemplateCollection {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      readMoreSeoTextButton
      readMoreSeoTextButtonActive
    }
  }
`

const Component = memo(({ data, ...props }) => {
  const { trackHotjarEvent } = useHotjar()

  trackHotjarEvent("collection_page")

  return <Template {...props} {...data} />
})
export default Component
