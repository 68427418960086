import React from "react"

import { SmallBody } from "../Styled/Text"
import {
  Wrapper,
  Label,
  Marker,
  Circle,
  StyledCheckbox,
} from "./CheckboxStyles"

export const Checkbox = ({
  checked,
  name,
  onChange,
  required = false,
  label,
  layout,
  className = "",
  value = ""
}) => (
  <Label htmlFor={name} className={className}>
    <StyledCheckbox
      id={name}
      checked={checked}
      name={name}
      onChange={onChange}
      required={required}
      type={`checkbox`}
      value={value}
    />
    {layout === "circle" ? (
      <Circle active={checked} />
    ) : (
      <Marker active={checked} />
    )}
    <SmallBody>{label}</SmallBody>
  </Label>
)

export const CheckboxButton = ({
  checked,
  onChange,
  label,
  layout = "",
  className = "",
}) => (
  <Wrapper className={className} onClick={onChange}>
    {layout === "circle" ? (
      <Circle active={checked} />
    ) : (
      <Marker active={checked} />
    )}
    <SmallBody>{label}</SmallBody>
  </Wrapper>
)
