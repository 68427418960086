import React from "react"
import { useApp } from "../../../hooks/useApp"
import { getAdditionalText } from "../../../utils/getAdditionalText"

export const withChildrenCollections = Component => ({
  name = "ChildrenCollection",
  childrenCollections,
}) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const {
    template: {
      collection: { additionalChildrenCollectionsTitleText },
    },
  } = getAdditionalText()

  const collections = childrenCollections.map(collection => ({
    title: collection.title,
    url: `${routes.COLLECTION}/${collection.shopify.handle}`,
    id: collection.shopify.id,
  }))

  Component.displayName = name
  return childrenCollections?.length > 0 ? (
    <Component
      collections={collections}
      title={additionalChildrenCollectionsTitleText}
    />
  ) : null
}
