import React, { memo, useMemo, useState } from "react"
import {
  Outer,
  Inner,
  Selected,
  CheckboxContainer,
  Checkbox,
  CheckboxAlt,
  CheckboxLabel,
  RadioContainer,
  Radio,
  RadioLabel,
} from "./AllFiltersStyles"
import { Wrapper, SubTitle, CategoryList, ShowMore } from "../Filter/Box/BoxStyles"
import { withAllFilters } from "./withAllFilters"
import { Accordion } from "../../../Accordion/Accordion"
import { Filter } from "@usereactify/search"
import { ListItem, Input, FilterLayout, SwatchValue } from  "../Filter/FilterStyles"
import { useSwatch } from "../../../../hooks/useSwatch"
import { Label as SwatchLabel, Img } from "../Filter/Swatch/SwatchStyles"
import { formatLabel } from "../../../../helpers/utils"

export const AllFilters = withAllFilters(
  ({
    filter,
    additionalAllOptionSelected,
    additionalOptionSelected,
    filterCount
  }): JSX.Element => {
      return (
        <Outer>
          <Inner>
            <Accordion 
              key={filter.id} 
              title={filter.name} 
              layout={"filter"} 
              subtitle={() => (<Selected>   {filter?.handle === 'price' ? undefined : filterCount > 0
                ? `${filterCount} ${additionalOptionSelected}`
                : `${additionalAllOptionSelected}`}</Selected> )}
              >
              <Filter
                key={filter.id}
                filter={filter}
                renderFilterRange={({ filter, filterRangeProps }) => {
                  const options = useMemo(() => filterRangeProps?.options?.filter(({ key }) => !filter.valuesExclude.includes(key)), [filter.valuesExclude, filterRangeProps?.options])
                  return (
                    <FilterListItem {...filterRangeProps} filter={filter} options={options}/>
                  )
                }}
                renderFilterList={({ filter, filterListProps }) => {
                  const options = useMemo(() => filterListProps?.options?.filter(({ key }) => !filter.valuesExclude.includes(key)), [filter.valuesExclude, filterListProps?.options])
                  
                  return (
                    <FilterListItem {...filterListProps} filter={filter} options={options} />
                  )
                }}
                
              />
            </Accordion>
          </Inner>
        </Outer>
      )
    },
)

const FilterListItem = memo(({ filter, options, handleChange }) => {
  const [expandedGroups, setExpandedGroups] = useState({})
  const groupedOptions = useMemo(() => {
    let currentSubLabel = ''
    const groups: any[] = []
    let currentGroupItems: any[] = []
 
    options
      ?.filter(({ key }) => !filter.valuesExclude.includes(key))
      ?.forEach((option: any) => {
        let value = filter.settingsUppercase ? option?.key?.toUpperCase() : option?.key
        if (filter?.handle === "price") {
          value = value
            ?.split("-")
            ?.map((price: string) => `${price}`)
            ?.join("-")
        }

        const modifiedOption = {
          ...option,
          value,
          ...filter,
        }

        if ((filter.name === 'size' || filter?.displayView === 'box') && option?.label?.includes('|')) {
          const [subLabel, subValue] = option.label.split('|')
          modifiedOption.subValue = subValue

          if (subLabel !== currentSubLabel) {
            if (currentGroupItems.length > 0) {
              groups.push({ subLabel: currentSubLabel, items: currentGroupItems })
            }
            currentSubLabel = subLabel
            currentGroupItems = []
          }

          currentGroupItems.push(modifiedOption )
        } else {
          groups.push(modifiedOption)
        }
      })

    if (currentGroupItems.length > 0) {
      groups.push({ subLabel: currentSubLabel, items: currentGroupItems })
    }

    return groups
  }, [filter, options])

  const sizeOrder = [
    "XXS",
    "XS",
    "S",
    "S/M",
    "M",
    "M/L",
    "L",
    "L/XL",
    "XL",
    "2XL",
    "3XL",
    "4XL",
    "5XL",
    "6XL",
  ]

  const brandOrder = [
    "RB Sellars",
    "Drizabone",
    "Rossi Boots"
  ]

  const sortedGroupOptions = groupedOptions.map(group => {
    if (group.items) {
      return {
        ...group,
        items: group.items.map(item => {
          return {
            ...item,
            label: item.label.split("|")[1],
          }
        })?.sort((a, b) => a.label - b.label)?.sort((a, b) => sizeOrder.indexOf(a.label) - sizeOrder.indexOf(b.label))
      }
    }
    return group
  }).sort((a, b) => brandOrder.indexOf(a.key) - brandOrder.indexOf(b.key))

  return (
    <Wrapper>
      {sortedGroupOptions.map((group, index) => {
        return (
          group.items ? (
            <React.Fragment key={index}>
              <SubTitle>{formatLabel(group.subLabel)}</SubTitle>
              <CategoryList
                role="group"
                key={index}
                isExpanded={expandedGroups[group.subLabel]}
              >
                {group.items.map(item => {
                  return (
                    <div key={item.key} onClick={() => handleChange?.(item.key)}>
                      <Box filter={filter} itemKey={item.key} {...item} />
                    </div>
                  )
                })}
              </CategoryList>

              {(group.items.length > 7 && !expandedGroups[group.subLabel]) && (
                <ShowMore
                  onClick={() => setExpandedGroups({ ...expandedGroups, [group.subLabel]: !expandedGroups[group.subLabel] })}
                >
                  <span>Show More</span>
                </ShowMore>
              )}
            </React.Fragment>
          ) : (
            <FilterLayout full={group.displayView !== "swatch"} key={group.key} onClick={() => handleChange?.(group.key)}>
              {group.displayView === "swatch" ? (
                <Swatch filter={filter} handleChange={handleChange} {...group} />
              ) : group.displayView === "range" ? (
                <Price filter={filter} handleChange={handleChange} {...group} />
              ) :
                <Check filter={filter} handleChange={handleChange} {...group} additionalKey={group?.key} />}
            </FilterLayout>
          )
        )
      })}
    </Wrapper>
  )
})

const Box = memo(({ checked, subValue, itemKey}) => (
  <CheckboxAlt checked={checked} displayValue={subValue} key={itemKey} />
))

const Swatch = memo(({ value, checked}) => {
  const { getSwatch } = useSwatch()
  const swatch = getSwatch(value.toLowerCase()) 

  return (
    <ListItem key={value?.toString()}>
      <SwatchLabel>
        <Img
          style={{
            background: swatch?.image
              ? `url("${swatch?.image}")`
              : swatch?.colour || "#e6e6e6",
          }}
          showSwatchBorder={value === "WHITE"}
          active={checked}
        />
        <Input
          type="checkbox"
          value={value}
          checked={value[value]}
        />
        <SwatchValue>
          {formatLabel(value)}
        </SwatchValue>
      </SwatchLabel>
    </ListItem>
  )
})

const Check = memo(({value, checked, itemKey, name, additionalKey}) => (
  <CheckboxContainer key={itemKey} className="group">
    <Checkbox checked={checked} />
    {name === 'Brand' ? (<CheckboxLabel>{additionalKey === 'Drizabone' ? 'Driza-Bone' : additionalKey}</CheckboxLabel>) : ( <CheckboxLabel>{formatLabel(value)}</CheckboxLabel>)}
  </CheckboxContainer>
))

const Price = memo(({ label, checked, key }) => (
  <RadioContainer key={key}>
    <Radio checked={checked} type="radio" />
    <RadioLabel>{formatLabel(label)}</RadioLabel>
  </RadioContainer>    
))