import React, { useMemo, useState } from "react"

import { getAdditionalText } from "../../../../utils/getAdditionalText"
import { useFilters } from "@usereactify/search"

export const withMobileFilters = Component => ({
  name = "MobileFilters",
  childrenCollections,
}) => {
  const {
    template: {
      collection: {
        additionalFilterAndSortText,
        additionalFilterRefineText,
        additionalClearAllFilterText,
        additionalApplyFiltersText,
        additionalAllOptionSelected,
        additionalOptionSelected,
      },
    },
  } = getAdditionalText()
  const { selected } = useFilters()

  const filterCount = useMemo(() => {
    if (selected) {
      return Object.keys(selected).reduce((acc, key) => {
        return acc + selected[key]?.value?.length
      }, 0)
    }
    return 0
  }, [selected])

  const [filterActive, setFilterActive] = useState(false)

  Component.displayName = name
  return (
    <Component
      filterActive={filterActive}
      setFilterActive={setFilterActive}
      childrenCollections={childrenCollections}
      additionalFilterAndSortText={additionalFilterAndSortText}
      additionalFilterRefineText={additionalFilterRefineText}
      additionalClearAllFilterText={additionalClearAllFilterText}
      additionalApplyFiltersText={additionalApplyFiltersText}
      additionalAllOptionSelected={additionalAllOptionSelected}
      additionalOptionSelected={additionalOptionSelected}
      filterActiveCount={filterCount}
    />
  )
}
