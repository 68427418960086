import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useRoutes } from "../../../../hooks/useRoutes"

export const withEmpty = Component => ({ name = "Empty", isCollection }) => {
  const { linkResolver } = useRoutes()
  const { searchEmpty, collectionEmpty } = useStaticQuery(graphql`
    query SANITY_PAGE_SEARCH_RESULTS_EMPTY_AND_SANITY_TEMPLATE_COLLECTION_RESULTS_EMPTY {
      searchEmpty: sanityPageSearch {
        searchNoResultsTitle
        searchNoResultsDescription
        searchNoResultsButton: _rawSearchNoResultsButton(
          resolveReferences: { maxDepth: 2 }
        )
      }
      collectionEmpty: sanityTemplateCollection {
        collectionNoResultsTitle
        collectionNoResultsDescription
      }
    }
  `)

  const {
    searchNoResultsTitle,
    searchNoResultsDescription,
    searchNoResultsButton,
  } = searchEmpty || {}
  const { collectionNoResultsTitle, collectionNoResultsDescription } =
    collectionEmpty || {}

  const title = isCollection ? collectionNoResultsTitle : searchNoResultsTitle
  const description = isCollection
    ? collectionNoResultsDescription
    : searchNoResultsDescription

  const link = linkResolver(searchNoResultsButton)

  Component.displayName = name
  return (
    <Component
      title={title}
      description={description}
      searchNoResultsButton={link}
      isCollection={isCollection}
    />
  )
}
