import tw, { styled } from "twin.macro"

export const Outer = styled.div`
  ${tw`w-full flex items-center justify-center col-span-3`}
  ${({ isCollection }) =>
    !isCollection
      ? tw`border-t border-b border-navy border-opacity-20`
      : tw`border-t border-navy border-opacity-20`}
`

export const Inner = tw.div`
  max-w-40 mx-auto py-4 md:py-8 text-center 
`

export const Title = styled.h3`
  ${({ large }) =>
    large
      ? tw`px-1 text-20 leading-1.25 tracking-2`
      : tw`text-14 md:text-20 leading-1.42 md:leading-1.25 tracking-10 md:tracking-2`}
  ${tw`font-bold mb-3-2 md:mb-3 uppercase`}
`

export const Description = tw.p`
  text-14 leading-1.78 mb-2 md:mb-2-8
`
