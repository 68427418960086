import React from "react"

import { withSearchPagination } from "./withSearchPagination"
import { Pagination } from "../../Pagination/Pagination"
import { PageSize } from "../../PageSize/PageSize"
import { PaginationWrapper, PageSizeWrapper } from "./SearchPaginationStyles"

export const SearchPagination = withSearchPagination(
  ({
    active,
    currentPage,
    handlePageChangeHandler,
    totalPages,
    currentPageSize,
    handleSelectedSizeChange,
    sizeOptions,
    additionalPageSizeText,
  }): JSX.Element =>
    active ? (
    <PaginationWrapper>
      <PageSizeWrapper>
        <PageSize
          selectedSize={currentPageSize}
          handleSelectedSizeChange={handleSelectedSizeChange}
          sizeOptions={sizeOptions}
          text={additionalPageSizeText} />
      </PageSizeWrapper>
      <div>
        <Pagination
          currentPage={currentPage}
          totalPageCount={totalPages}
          handlePageChange={handlePageChangeHandler} />
      </div>
    </PaginationWrapper>
  ) : null,
)
