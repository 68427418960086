import tw, { css, styled } from "twin.macro"

export const Label = tw.label`
  flex flex-row items-center w-full p-0-4 mb-1-2 cursor-pointer text-14 leading-1.42 tracking-5
`

export const Img = styled.div`
  ${tw`block relative w-2 h-2 border mr-1 border-transparent rounded-full p-0-4`}
  ${({ showSwatchBorder }) => {
    if (showSwatchBorder) {
      return css`border-color: #dadada`
    } else {
      return tw`border-transparent`
    }
  }}
  &:after {
    content: "";
    ${({ active }) =>
      active
        ? tw`absolute -top-0-4 -left-0-4 w-2-6 h-2-6 border border-grey rounded-full`
        : null
    }
  }
`
