import tw, { styled } from "twin.macro"

export const Wrapper = tw.div`
  mlg:hidden w-full md:w-auto
`

export const Outer = tw.div`
  w-full bg-white pt-3-6 pb-6 min-h-full-vh
`

export const Inner = tw.div`px-2`

export const Title = tw.h2`
  font-bold text-16 leading-1.25 tracking-10 uppercase pb-3-2 text-center border-b border-navy border-opacity-20
`

export const InnerTitle = tw.h3`
  font-bold text-16 leading-1.25 tracking-10 uppercase mb-0-8 mt-2-4
`

export const Buttons = tw.div`
  grid grid-cols-2 gap-1-6 mt-3-2
`

export const Selected = styled.p`
  ${tw`text-14 leading-1.78 mr-5-6 text-grey`}
  white-space: nowrap;
`
