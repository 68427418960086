import React from "react"

export const withPopup = Component => ({ name = "Popup", active, setActive, onClose, ...props }: PopupProps) => {
  const handleClick = () => {
    setActive && setActive(false)
    if (onClose) {
      onClose()
    }
  }

  Component.displayName = name
  return <Component active={active} handleClick={handleClick} {...props} />
}

type PopupProps = {
  name?: string
  active: boolean
  setActive?: any
  onClose?: any
  children: JSX.Element
  width: "sm" | "md" | "lg" | "xl" | "full" | "product"
  mobileAttachTop?: boolean
  outerClose?: boolean
  attachTop?: boolean
  transparent?: boolean
}
