import tw, { styled } from "twin.macro"
import { css } from "@emotion/react"
import { UnderlinedButton } from "../../../Styled/Button"

export const Outer = tw.div`
  mlg:block
`

export const Inner = tw.div`
  flex flex-col
`

export const Header = styled.div`
  ${tw`flex flex-row justify-between items-start pt-1-2 border-b border-navy`}
  ${({ isParentCollection }) => (isParentCollection ? tw`pb-2-4` : tw`pb-4-1`)}
`

export const Title = tw.h3`
  text-16 leading-1.25 tracking-10 font-semibold uppercase
`

export const Selected = tw.span`
  text-12 leading-1.67 tracking-5 text-navy text-opacity-60
`

export const Clear = styled(UnderlinedButton)`
  ${tw`uppercase block`}
  span {
    ${tw`text-12 leading-1.67 tracking-5`}
  }
`

export const CheckboxContainer = tw.div`
  flex items-center cursor-pointer mb-1-6 px-1-6 transition-all duration-200
`;

export const CheckboxLabel = tw.label`
  border-none cursor-pointer text-14
`;

export const Checkbox = styled.div`
  ${tw`relative inset-0 h-1-6 w-1-6 mr-1-6 bg-transparent border border-navy border-opacity-20 group-hover:border-opacity-100 rounded-2 transition-all duration-200`}
  &:after {
    content: "";
    ${tw`absolute top-0-2 left-0-5 w-0-4 h-0-8 border border-white transition-all duration-200`}
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) scale(0);
  }
  ${({ checked }) =>
    checked &&
    css`
      ${tw`bg-navy`}
      &:after {
        transform: rotate(45deg) scale(1);
      }
    `}
`

export const CheckboxAlt = styled.div(({ checked, displayValue }) => [
  tw`
    h-3 w-full border border-navy rounded-5 relative cursor-pointer transition-all duration-200 ease-in-out
  `,
  checked && tw`bg-navy`,
  `
    &:after {
      content: '${displayValue}';
      color: ${checked ? 'white' : 'text-navy'};
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 0.75rem;
    }
  `,
]);

export const RadioContainer = tw.div`
  relative flex items-center cursor-pointer mb-1-6
`;

export const RadioLabel = tw.label`
  ml-1 border-none cursor-pointer text-14
`;

export const Radio = styled.input(({ checked }) => [
  tw`
    h-2 w-2 border border-grey rounded-full relative transition-all duration-200 ease-in-out
  `,
  css`
  &:after {
    content: '';
    background-color: ${checked ? '#0C2340' : 'white'};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.75rem;
    height:0.85rem;
    width:0.85rem;
    border-radius: 99999px;
  }
  `
]);