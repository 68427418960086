import { graphql, useStaticQuery } from "gatsby"


export const useSwatch = () => {
  const { swatches: rawSwatches } =  useStaticQuery(graphql`
    query SWATCHES_SANITY_SETTINGS {
      swatches: allSanitySettingSwatches {
        edges {
          node {
            id: _id
            name
            slug {
              current
            }
            linked
            colour {
              hex
            }
            image {
              asset {
                url
              }
            }
          }
        }
      }
    }`)   

  const swatches =
    rawSwatches?.edges?.map(({ node }) => ({
      name: node?.name,
      slug: node?.slug?.current,
      linked: node?.linked,
      colour: node?.colour?.hex,
      image: node?.image?.asset?.url || false,
    })) || []

  const getSwatch = value => {
    const swatch = swatches.find(
      swatch =>
        swatch?.name?.toLowerCase() === value?.toLowerCase() ||
        swatch?.linked
          ?.map(item => item.toLowerCase())
          .includes(value?.toLowerCase()),
    )
    return swatch ?? false
  }
  return { getSwatch }
}
