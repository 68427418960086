import React from "react"
import loadable from '@loadable/component'
import { useHotjar} from '../../hooks/useHotjar'

export const withSections = Component => ({
  name = "Sections",
  page,
  ...props
}) => {

  const { trackHotjarEvent } = useHotjar()

  const sections = page?.content?.map(section => {
    const name = section?._type?.replace("section", "")

    const SectionComponent = loadable(() => import(`./${name}/${name}`))

    return SectionComponent
      ? {
          ...section,
          component: () => (
            <SectionComponent {...props} {...section} />
          ),
        }
      : undefined
  })

  const trackSectionClick = (sectionKey) => {
    trackHotjarEvent("section_click")
    trackHotjarEvent("section_click-"+sectionKey)
  }

  Component.displayName = name
  return <Component {...props} content={sections} trackSectionClick={trackSectionClick} />
}
