import tw, { styled } from "twin.macro"

export const List = tw.ul`
  px-1-6 text-14 leading-1.42 tracking-5 flex flex-wrap
`

export const ListItem = tw.li`
  list-none
`

export const Input = tw.input`
  hidden
`

export const FilterLayout = styled.div`
  ${({ full }) => (full ? tw`w-full` : tw`w-1/2 inline-block`)}
`

export const SwatchValue = styled.div`
  ml-1
`