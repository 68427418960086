import React from "react"

import { withEmpty } from "./withEmpty"
import { Outer, Inner, Title, Description } from "./EmptyStyles"
import { StyledButton } from "../../../Styled/Button"
import { Link } from "../../../Link/Link"

export const Empty = withEmpty(
  ({
    title,
    description,
    searchNoResultsButton,
    isCollection,
  }): JSX.Element => (
    <Outer isCollection={isCollection}>
      <Inner>
        <Title large={isCollection}>{title}</Title>
        <Description>{description}</Description>
        {!isCollection && searchNoResultsButton?.title?.length > 0 ? (
          <StyledButton size="primary" colour={"navyBorderedWithNavyText"}>
            <Link
              title={searchNoResultsButton?.title}
              url={searchNoResultsButton?.url}
              external={searchNoResultsButton?.external}
              isExternal={searchNoResultsButton.isExternal}
            />
          </StyledButton>
        ) : null}
      </Inner>
    </Outer>
  ),
)
