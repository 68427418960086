import React, { useCallback } from "react"
import { useSortBy } from "@usereactify/search"

export const withMobileSort = Component => ({ name = "MobileSort" }) => {
  const { sortOption, sortOptions, setSortOption } = useSortBy()

  const handleSortChange = useCallback(
    value => {
      setSortOption(value)
    },
    [setSortOption],
  )

  Component.displayName = name
  return (
    <Component
      handleSortChange={handleSortChange}
      options={sortOptions}
      sortBy={sortOption}
    />
  )
}
