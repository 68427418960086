import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

export const Outer = tw.div`
  mb-2
`

export const Inner = tw.div`
  flex flex-col py-1-6 border-b mlg:border-navy border-opacity-60 mlg:border-opacity-20
`

export const Header = tw.div`
  pt-2-4 mlg:pt-1-2 pb-0-4 mlg:pb-4-1 mlg:border-b mlg:border-navy
`

export const Title = tw.h3`
  text-16 leading-1.25 tracking-10 font-semibold uppercase
`

export const StyledLink = styled(Link)`
  ${tw`py-0-8 text-14 leading-1.42`}
`
