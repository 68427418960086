import tw, { styled } from "twin.macro"

export const ResultsWrapper = styled.div`
  ${tw`relative`}

  .search-results {
    ${tw`grid grid-cols-2 gap-x-1-5 gap-y-3 `}
  }

  @media (min-width: 769px) {
    .search-results {
      ${tw`grid-cols-3 gap-x-3 gap-y-4`} 
    }
  }
`
