import { graphql, useStaticQuery } from "gatsby"
import React, { useState, useRef } from "react"

export const withSearchResults = Component => ({
  name = "SearchResults",
  collectionHandle = undefined,
  collectionsObjForBreadCrumbs = [],
  tiles = [],
}) => {
  const isCollection = collectionsObjForBreadCrumbs.length > 0

  const { collectionTemplate } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_COLLECTION_PAGE_SIZE {
      collectionTemplate: sanityTemplateCollection {
        sizeOptions
      }
    }
  `)

  const { sizeOptions } = collectionTemplate || {}

  const [currentPageSize, setCurrentPageSize] = useState(sizeOptions?.[0] ?? 24)
  // useRef to track errro state
  const errorRef = useRef(null)

  const handleSelectedSizeChange = sizeValue => {
    setCurrentPageSize(sizeValue)
  }

  Component.displayName = name
  
  return (
    <Component
      isCollection={isCollection}
      collectionHandle={collectionHandle}
      collectionsObjForBreadCrumbs={collectionsObjForBreadCrumbs}
      tiles={tiles}
      currentPageSize={currentPageSize}
      sizeOptions={sizeOptions}
      handleSelectedSizeChange={handleSelectedSizeChange}
      errorRef={errorRef}
    />
  )
}
