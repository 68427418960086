import tw, { styled } from "twin.macro"
import { UnderlinedButton } from "../../../../Styled/Button"

export const Label = styled.label`
  ${tw`w-full block border border-navy whitespace-nowrap rounded-5 text-center text-14 leading-1.78 cursor-pointer`}
  ${({ active }) =>
    active ? tw`text-white bg-navy` : tw`text-navy bg-transparent`}
`

export const Wrapper = styled.div`
  ${tw`w-full py-2-4 border-b border-navy border-opacity-20`}
  :first-of-type {
    ${tw`pt-0-8`}
  }
  :last-of-type {
    ${tw`border-b-0`}
  }
`

export const SubTitle = styled.h4`
  ${tw`text-14 leading-1.71 mb-1-6 `}
  &:not(:first-child) {
    ${tw`border-t border-grey-lightest mt-3 pt-1-6`}
  }
`;

export const ShowMore = styled(UnderlinedButton)`
  ${tw`col-span-7 mt-0-5`}
`

export const CategoryList = styled.div`
  ${tw`grid grid-cols-7 gap-0-8 overflow-hidden duration-1000 transition-all ease-in-out`}
  ${({ isExpanded }) =>
  isExpanded ? tw`max-h-[100px]` : tw`max-h-[35px]`}
`