import React, { memo, useCallback } from "react"
import { Link } from "gatsby"
import { Filters, ReactifySearchProvider, Sensors, ClearAll } from "@usereactify/search"
import { withCollection } from "./withCollection"
import {
  Wrapper,
  BreadCrumbs,
  Divider,
  Panel,
  Stats,
  InnerWrapper,
  FilterWrapper,
  ResultsWrapper,
  BreadCrumbWrapper,
  BreadCrumbLink,
} from "./CollectionStyles"
import { SearchResults } from "../Search/Results/SearchResults"
import { AllFilters } from "../Search/Filters/AllFilters/AllFilters"
import { MobileFilters } from "../Search/Filters/MobileFilters/MobileFilters"
import { DesktopSort } from "../Search/Sort/DesktopSort/DesktopSort"
import { ChildrenCollections } from "./ChildrenCollections/ChildrenCollections"
import SeoBottomCopy from "./SeoBottomCopy"
import { Sections } from "../Sections/Sections"
import { Header, Title, Clear } from "../Search/Filters/AllFilters/AllFiltersStyles"
import SelectedFilters from "@appbaseio/reactivesearch/lib/components/basic/SelectedFilters"

export const Collection = withCollection(memo(
  ({
    handle,
    collectionsObjForBreadCrumbs,
    parentCollection,
    childrenCollections,
    stats,
    tiles,
    isParentCollection,
    windowSizeDesignation,
    shopifyPermanentDomain,
    collection,
    template,
    additionalFilterProductsText,
    additionalClearFilterText,
    additionalAllOptionSelected,
    additionalOptionSelected,
  }): JSX.Element => {
    const memoizedRenderFilter = useCallback((props) => (
      <AllFilters
        {...props}
        additionalAllOptionSelected={additionalAllOptionSelected}
        additionalOptionSelected={additionalOptionSelected}
      />      
    ), [additionalAllOptionSelected, additionalOptionSelected])

    return (
    <>
      <Wrapper noPadding>
        <BreadCrumbs>
          {collectionsObjForBreadCrumbs.length ? (
            collectionsObjForBreadCrumbs
              .reduce((arr, item) => {
                if (!arr.find(i => i.handle === item.handle)) {
                  arr.push(item)
                }
                return arr
              }, [])
              .map((breadcrumb, index) => {
                return (
                  <BreadCrumbWrapper key={index}>
                    {index !== collectionsObjForBreadCrumbs.length - 1 ? (
                      <Link
                        to={`/collections/${breadcrumb.handle}`}
                        title={breadcrumb.title}
                      >
                        <BreadCrumbLink>
                          {breadcrumb.title.replace("‘", "'")}
                        </BreadCrumbLink>
                        /
                      </Link>
                    ) : (
                      breadcrumb.title.replace("‘", "'")
                    )}
                  </BreadCrumbWrapper>
                )
              })
          ) : parentCollection?.title ? (
            <Link to={parentCollection.url} title={parentCollection.title}>
              {parentCollection.title.replace("‘", "'")} /
            </Link>
          ) : (
            <span>Shop</span>
          )}
        </BreadCrumbs>
      </Wrapper>
      {collection?.hero && <Sections page={{ content: collection?.hero }} />}

      <ReactifySearchProvider
        mode="collection"
        shopifyPermanentDomain={shopifyPermanentDomain}
        collectionHandle={collection?.shopify?.handle || ""}
        theme={{
          typography: {
            fontFamily: "inherit",
          },
          colors: {
            textColor: "#0C2340",
            backgroundColor: "#fff",
            primaryTextColor: "#0C2340",
            primaryColor: "#0C2340",
            titleColor: "#0C2340",
            alertColor: "#B30838",
            borderColor: "#fff",
          },
        }}
      >
        <Sensors />
        <Wrapper>
          <Divider />
          <InnerWrapper>
            <FilterWrapper>
              <ChildrenCollections childrenCollections={childrenCollections} />
              {windowSizeDesignation === "desktop" && (
                <>
                  <Header isParentCollection={isParentCollection}>
                    <Title>{additionalFilterProductsText}</Title>
                    <SelectedFilters
                      render={({ clearValues }) => (
                        <Clear colour="underlinedLinkNavyLight" onClick={clearValues}>
                          <span>{additionalClearFilterText}</span>
                        </Clear>
                      )} 
                    />
                  </Header>
                  <Filters
                    renderFilterRange={memoizedRenderFilter}
                    renderFilterList={memoizedRenderFilter}
                  />
                </>
              )}
            </FilterWrapper>
            <ResultsWrapper>
              <Panel>
                <Stats>{stats}</Stats>
                {windowSizeDesignation === "mobile" ? (
                  <MobileFilters childrenCollections={childrenCollections}/>
                ) : (
                  <DesktopSort />
                )}
              </Panel>

              <SearchResults
                tiles={tiles}
                collectionHandle={handle}
                collectionsObjForBreadCrumbs={collectionsObjForBreadCrumbs}
              />
              
            </ResultsWrapper>
          </InnerWrapper>
        </Wrapper>
      </ReactifySearchProvider>

      {collection?.seoBottomCopy && (
        <Wrapper>
          <InnerWrapper>
            <FilterWrapper></FilterWrapper>
            <ResultsWrapper>
              <SeoBottomCopy
                seoCopy={collection?.seoBottomCopy}
                readMoreSeoTextButton={template?.readMoreSeoTextButton}
                readMoreSeoTextButtonActive={
                  template?.readMoreSeoTextButtonActive
                }
              />
            </ResultsWrapper>
          </InnerWrapper>
        </Wrapper>
      )}
    </>
  )}
))
