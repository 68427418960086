import tw, { styled } from "twin.macro"
import { RichText } from "../RichText/RichText"
import { css } from "@emotion/react"
import { UnderlinedButton } from "../Styled/Button"

export const Wrapper = styled.div`
  ${tw`w-full max-w-full mx-auto px-2 md:px-3`}
  ${({ noPadding }) => noPadding ? tw`p-0` : tw`pt-3-2 pb-4 mlg:pt-4 mlg:pb-8`}
`

export const Title = tw.div`
  font-bold uppercase text-25 mlg:text-35 leading-1.4 mlg:leading-1.28 tracking-10 text-center mlg:text-left mb-0-8 mlg:mb-3-2
`

export const BreadCrumbs = tw.div`
  text-14 leading-1.71 mb-0-8 text-center mlg:text-left text-navy text-opacity-60 pt-3-2 mlg:pt-4
`

export const Panel = tw.div`
  flex flex-col mlg:flex-row items-center justify-between mb-3-2
`

export const Divider = tw.hr`hidden mlg:block text-navy text-opacity-20 mb-3-2`

export const IconWrapper = tw.span`
  mlg:pl-1-2 absolute mlg:static right-1-6
`

export const Stats = styled.p`
  ${tw`text-center text-14 leading-1.71 text-navy text-opacity-60 mb-2-4 mlg:mb-0`}
`

export const InnerWrapper = tw.div`
  grid grid-cols-1 mlg:grid-cols-4 gap-3
`

export const FilterWrapper = tw.div`
  col-span-1 hidden mlg:block
`

export const ResultsWrapper = tw.div`
  col-span-1 mlg:col-span-3
`

export const BreadCrumbWrapper = tw.span`
  mr-0-7
`

export const BreadCrumbLink = tw.span`
  underline mr-0-7
`

export const SeoBottomCopyWrapper = tw.div`
  pb-2
`

export const SeoBottomCopyText = styled(RichText)`
  ${tw`mb-1`}
  ${css`
    transition: max-height 0.3s ease-in-out;
    max-height: calc(1.6rem * 3);
    overflow: hidden;
  `}
    ${({ active, maxHeight }) =>
    active &&
    maxHeight &&
    css`
        max-height: ${maxHeight || 250}px;
      `}
`

export const SeoBottomCopyButton = styled(UnderlinedButton)`
  ${tw`font-bold uppercase tracking-10`}
  ${css`
    span {
      margin: 0;
    }
  `}
`