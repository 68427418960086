import React, { useCallback } from "react"
import { useSortBy } from "@usereactify/search"

export const withDesktopSort = Component => ({ name = "Sort" }) => {
  const { sortOption, sortOptions, setSortOption } = useSortBy()

  const handleSortChange = useCallback(
    ({ target: { value } }) => {
      setSortOption(value)
    },
    [setSortOption],
  )

  Component.displayName = name
  return (
    <Component
      handleSortChange={handleSortChange}
      options={sortOptions}
      sortBy={sortOption}
    />
  )
}
