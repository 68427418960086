import React from "react"

import { useSale } from "../../hooks/useSale"

export const withSection = Component => ({
  name = "Section",
  children,
  settings,
  onClick
}) => {
  const { hide, promotion, promotionVisibility } = settings || {}

  const { getSaleVisibility } = useSale()

  const visibility =
    hide === "hide" ? "hide" : getSaleVisibility(promotion, promotionVisibility)

  Component.displayName = name
  return visibility !== "hide" ? (
    <Component {...settings} onClick={onClick}>{children}</Component>
  ) : null
}
