import React, { memo } from "react"
import { AllFilters } from "../AllFilters/AllFilters"
import { withMobileFilters } from "./withMobileFilters"
import {
  Wrapper,
  Outer,
  Inner,
  Title,
  InnerTitle,
  Buttons,
} from "./MobileFiltersStyles"
import { Popup } from "../../../Popup/Popup"
import { StyledButton } from "../../../Styled/Button"
import { ChildrenCollections } from "../../../Collection/ChildrenCollections/ChildrenCollections"
import { MobileSort } from "../../Sort/MobileSort/MobileSort"
import { Filters, ClearAll } from "@usereactify/search"

// This component need to be mounted and rendered all the time. The Modal component is not suitable

export const MobileFiltersComponent = 
  ({
    filterActive,
    setFilterActive,
    childrenCollections,
    additionalFilterAndSortText,
    additionalFilterRefineText,
    additionalClearAllFilterText,
    additionalApplyFiltersText,
    additionalAllOptionSelected,
    additionalOptionSelected,
    filterActiveCount,
  }): JSX.Element => {
    return (
      <Wrapper>
        <StyledButton
          wide
          size="primary"
          colour="navyBorderedWithNavyText"
          onClick={() => setFilterActive(true)}
        >
          <span>Filter & Sort {filterActiveCount > 0 && `(${filterActiveCount})`}</span>
        </StyledButton>
        <Popup
          active={filterActive}
          setActive={setFilterActive}
          width={"full"}
          mobileAttachTop
        >
          <Outer>
            <Title>{additionalFilterAndSortText}</Title>
            <Inner>
              <ChildrenCollections childrenCollections={childrenCollections} />
              <InnerTitle>{additionalFilterRefineText}</InnerTitle>
              <MobileSort />
         
              <Filters
                renderFilterRange={props => {
                  return (
                    <AllFilters
                      {...props}
                      additionalAllOptionSelected={additionalAllOptionSelected}
                      additionalOptionSelected={additionalOptionSelected}
                    />
                  )
                }}
          
                renderFilterList={props => {
                  return (
                    <AllFilters
                      {...props}
                      additionalAllOptionSelected={additionalAllOptionSelected}
                      additionalOptionSelected={additionalOptionSelected}
                    />
                  )
                }}
              />
       
              <Buttons>
                <ClearAll showWhenEmpty render={({clearAll}) =>  
                  <>
                    <StyledButton
                      onClick={clearAll}
                      colour={"navyBorderedWithNavyText"}
                      size={"primary"}
                      wide
                    >
                      {additionalClearAllFilterText}
                    </StyledButton>
                    <StyledButton
                    size={"primary"}
                    wide
                    onClick={() => setFilterActive(false)}
                  >
                    {additionalApplyFiltersText}
                  </StyledButton>
                </>
                }/>
              </Buttons>
            </Inner>
          </Outer>
        </Popup>
      </Wrapper>
    )
  }


export const MobileFilters = withMobileFilters(memo(MobileFiltersComponent))