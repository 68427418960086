import React from "react"
import { withChildrenCollections } from "./withChildrenCollections"
import {
  Title,
  Outer,
  Inner,
  Header,
  StyledLink,
} from "./ChildrenCollectionsStyles"

export const ChildrenCollections = withChildrenCollections(
  ({ title, collections }) => (
    <Outer>
      <Header>
        <Title>{title}</Title>
      </Header>
      <Inner>
        {collections.map(({ url, title, id }) => (
          <StyledLink key={id} to={url} title={title}>
            {title}
          </StyledLink>
        ))}
      </Inner>
    </Outer>
  ),
)
