import React, { useEffect, useMemo } from "react"

import { useApp } from "../../hooks/useApp"
import { useRoutes } from "../../hooks/useRoutes"
import { useWindowSize } from "../../hooks/useWindowSize"
import { getAdditionalText } from "../../utils/getAdditionalText"

export const withCollection =
  Component =>
  ({ name = "Collection", collection, template }) => {
    const { activeCollection, setActiveCollection, globalState } = useApp()

const {
  template: {
    collection: {
      additionalFilterProductsText,
      additionalClearFilterText,
      additionalAllOptionSelected,
      additionalOptionSelected,
    },
  },
} = getAdditionalText()
    const { linkResolver } = useRoutes()

    const {
      config: {
        services: {
          reactify: { index },
          shopify: { defaultShopName },
        },
      },
    } = useApp()

    const [
      {
        resultsStats: { currentPage, pageSize, numberOfResults },
      },
    ] = globalState

    const parentCollection = useMemo(() => linkResolver(collection.parentCollection), [collection.parentCollection, linkResolver])

    useEffect(() => {
      if (collection?.id !== activeCollection?.id) {
        setActiveCollection(collection)
      }
    }, [collection, activeCollection?.id, setActiveCollection])

    const stats = useMemo(() => 
      numberOfResults > 0
        ? `Showing ${currentPage * pageSize + 1} - ${
            (currentPage + 1) * pageSize < numberOfResults
              ? (currentPage + 1) * pageSize
              : numberOfResults
          } of ${numberOfResults} products`
        : ``
    , [currentPage, pageSize, numberOfResults])

    const isParentCollection = useMemo(() => collection.childrenCollections.length > 0, [collection.childrenCollections])

    const parentCollectionObjForBreadCrumbs = useMemo(() => collection.parentCollection?.title?.length > 0
        ? {
            title: collection.parentCollection?.title,
            handle: collection.parentCollection?.shopify?.shopifyHandle,
          }
        : undefined
    , [collection.parentCollection])

    const collectionObjForBreadCrumbs = useMemo(() => ({
      title: collection.title,
      handle: collection.shopify.handle,
    }), [collection.title, collection.shopify.handle])

    const collectionsObjForBreadCrumbs = useMemo(() => parentCollectionObjForBreadCrumbs
      ? [parentCollectionObjForBreadCrumbs, collectionObjForBreadCrumbs]
      : [collectionObjForBreadCrumbs], [parentCollectionObjForBreadCrumbs, collectionObjForBreadCrumbs])

    const { windowSize } = useWindowSize()

    const windowSizeDesignation = React.useMemo(() => {
      return !windowSize.width
        ? "unknown"
        : windowSize.width <= 1024
        ? "mobile"
        : "desktop"
    }, [windowSize])

    Component.displayName = name
    return (
      <Component
        title={collection.title}
        handle={collection.shopify.handle}
        collectionsObjForBreadCrumbs={collectionsObjForBreadCrumbs}
        parentCollection={parentCollection}
        childrenCollections={collection.childrenCollections}
        stats={stats}
        tiles={collection.tiles}
        isParentCollection={isParentCollection}
        windowSizeDesignation={windowSizeDesignation}
        index={index}
        shopifyPermanentDomain={`${defaultShopName}.myshopify.com`}
        collection={collection}
        template={template}
        additionalFilterProductsText={additionalFilterProductsText}
        additionalClearFilterText={additionalClearFilterText}
        additionalAllOptionSelected={additionalAllOptionSelected}
        additionalOptionSelected={additionalOptionSelected}
      />
    )
  }
