import tw, { styled } from "twin.macro"

export const SectionWrapper = styled.div`
  ${tw`mx-auto relative`}
  ${tw`overflow-x-hidden`}
  ${({ maxWidth }) => {
    switch (maxWidth) {
      case "sm":
        return tw`max-w-76`
      case "md":
        return tw`max-w-86`
      case "mlg":
        return tw`max-w-113`
      case "lg":
        return tw`max-w-lg`
      case "xl":
        return tw`max-w-xl`
      case "full":
        return tw`max-w-full`
      default:
        return tw`max-w-xl`
    }
  }}

  ${({ horizontalSpacing }) => {
    switch (horizontalSpacing) {
      case "default":
        return tw`px-2 md:px-3`
      case "slider":
        return tw`px-0 md:px-3`
      case "none":
        return tw`px-0`
      default:
        return tw`px-2 md:px-3`
    }
  }}

  ${({ topPaddingSpacing }) => {
    switch (topPaddingSpacing) {
      case "sm":
        return tw`pt-1 md:pt-2`
      case "md":
        return tw`pt-2 md:pt-4`
      case "desktopMd":
        return tw`pt-0 md:pt-4`
      case "lg":
        return tw`pt-3 md:pt-6`
      case "none":
        return tw`pt-0`
      default:
        return tw`pt-2 md:pt-4`
    }
  }}

  ${({ bottomPaddingSpacing }) => {
    switch (bottomPaddingSpacing) {
      case "sm":
        return tw`pb-1 md:pb-2`
      case "md":
        return tw`pb-2 md:pb-4`
      case "desktopMd":
        return tw`pb-0 md:pb-4`
      case "lg":
        return tw`pb-3 md:pb-6`
      case "none":
        return tw`pb-0`
      default:
        return tw`pb-2 md:pb-4`
    }
  }}

  ${({ borderTop }) => {
    return borderTop === "true"
      ? tw`border-t border-black border-opacity-50`
      : null
  }}

  ${({ borderBottom }) => {
    return borderBottom === "true"
      ? tw`border-b border-black border-opacity-50`
      : null
  }}

  ${({ hideDesktop }) => {
    return hideDesktop === "hide" ? tw`md:hidden` : null
  }}

  ${({ hideMobile }) => {
    return hideMobile === "hide" ? tw`hidden md:block` : null
  }}

  background: ${({ bgColor }) => bgColor?.hex || "#fff"};
`
