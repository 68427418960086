import React from "react"
import { withSection } from "./withSection"

import { SectionWrapper } from "./SectionStyles"

export const Section = withSection(
  ({
    children,
    topPaddingSpacing,
    bottomPaddingSpacing,
    horizontalSpacing,
    maxWidth,
    borderTop,
    borderBottom,
    hideDesktop,
    hideMobile,
    bgColor,
    onClick
  }) => (
    <SectionWrapper
      topPaddingSpacing={topPaddingSpacing}
      bottomPaddingSpacing={bottomPaddingSpacing}
      horizontalSpacing={horizontalSpacing}
      maxWidth={maxWidth}
      borderTop={borderTop}
      borderBottom={borderBottom}
      hideDesktop={hideDesktop}
      hideMobile={hideMobile}
      bgColor={bgColor}
      onClick={onClick}
    >
      {children}
    </SectionWrapper>
  ),
)
