import tw, { styled, css } from "twin.macro"

export const Label = styled.label`
  ${tw`flex items-center relative cursor-pointer select-none`}
`

export const Wrapper = tw.div`flex items-center relative cursor-pointer`

export const StyledCheckbox = tw.input`absolute cursor-pointer opacity-0 h-0 w-0`

export const Marker = styled.span`
  ${tw`relative inset-0 h-1-6 w-1-6 mr-1-6 bg-transparent border border-navy border-opacity-20 group-hover:border-opacity-100 rounded-2 transition-all duration-200`}
  &:after {
    content: "";
    ${tw`absolute top-0-2 left-0-5 w-0-4 h-0-8 border border-white transition-all duration-200`}
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) scale(0);
  }
  ${({ active }) =>
    active &&
    css`
      ${tw`bg-navy`}
      &:after {
        transform: rotate(45deg) scale(1);
      }
    `}
`

export const Circle = styled.span`
  ${tw`box-border relative h-2-4 w-2-4 border border-navy border-opacity-60 rounded-full mr-0-8 transition-all duration-200`}
  &:after {
    content: "";
    ${tw`absolute box-border top-0-2 left-0-2 w-1-8 h-1-8 border border-transparent rounded-full bg-transparent transition-all duration-200`}
  }
  ${({ active }) =>
    active &&
    css`
      &:after {
        ${tw`bg-navy border-navy`}
      }
    `}
`
